import React from 'react';
import logo from '../Great Dane Logo.svg';
import SocietyCountdown from "./SocietyCountdown";
import {Grid} from 'semantic-ui-react'
import GreatDaneContents from "./GreatDaneContents";


const Home = () => {
    return (
        <div className={'site'}>
            <Grid>
                <Grid.Row>
                    <Grid.Column className='Home'>
                        <img src={logo} className="App-logo" alt="logo"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className='Home' columns={1}>
                        <SocietyCountdown/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className='Home'>
                        <GreatDaneContents/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default Home