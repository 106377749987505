import React from 'react';
import {Header, Segment, Statistic} from 'semantic-ui-react'

const GreatDaneResults = () => {
    return (
        <div>
            <Header as='h3' icon='trophy' content='Results' />
            <Segment>
                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQDACE5BA6mu6wppvntSUgNYQxFOM77qLrbYvg1cAi0jRu8xUvRmSMhV5j0ly-P2Awk7sZ0r3XGZ1-R/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
            </Segment>
        </div>
    )
}

export default GreatDaneResults