import React from 'react';

import {Card, Grid, Header, Image} from 'semantic-ui-react'
import _ from 'underscore';
import data from '../bios.json'

const DawgsBios = () => {
    const bios = _.map(data, (bio, i) => {
        return (
            <Card key={i}>
                <Card.Content>
                    <Image
                        floated='right'
                        size='mini'
                        src={'./dawgs/'+bio.image}
                    />
                    <Card.Header>{bio.name}</Card.Header>
                    <Card.Meta>{bio.metadata}</Card.Meta>
                    <Card.Description style={{textAlign: 'justify'}}>
                        {bio.bio}
                    </Card.Description>
                </Card.Content>
            </Card>)
    })
    return (
        <div>
            <Header as='h3' icon='paw' content='The Dawgs'/>
            <Card.Group itemsPerRow={3}>
                {bios}
            </Card.Group>
        </div>
    )
}

export default DawgsBios