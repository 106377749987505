import React from 'react';
import {Button, Header, Icon, Image, Item, Label, Segment} from 'semantic-ui-react'

const GreatDaneDrinkWeek = () => {
    return (
        <div>
            <Header as='h3' icon='flask' content='Drink of the Week'/>
            <Segment>
                <Item.Group divided>
                    <Item>
                        <Item.Image size='medium' src='https://www.thecocktaildb.com/images/media/drink/b522ek1521761610.jpg'/>

                        <Item.Content>
                            <Item.Header as='a'>Tipperary</Item.Header>
                            <Item.Meta>
                                <span className='cinema'>Serve: Nick and Nora Glass</span>
                            </Item.Meta>
                            <Item.Extra>
                                <span>Ingredients: </span>
                                <Label>2 oz Irish Whiskey</Label>
                                <Label>1 oz Sweet Vermouth</Label>
                                <Label>1/2 Green Chartreuse</Label>
                            </Item.Extra>
                            <Item.Description>Stir over ice. Strain into chilled glass. Cut a wide swath of orange peel, and express the orange oils over the drink. Discard orange twist.
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        </div>
    )
}

export default GreatDaneDrinkWeek