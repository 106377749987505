import React from 'react';
import Countdown from 'react-countdown';
import {Grid, Header, Icon, Segment, Statistic} from "semantic-ui-react";
import GreatDaneResults from "./GreatDaneResults";
import GreatDaneDrinkWeek from "./GreatDaneDrinkWeek";

// Random component
const PlayTime = () => <span>It is Play Time!</span>

// Renderer callback with condition
const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a completed state
        return <PlayTime/>;
    } else {
        return <div className='Countdown' >
            <Segment textAlign='center' size='tiny' basic>
                <Header as='h2' textAlign='center'>
                    <Icon name='users' />
                    <Header.Content>Next Conclave</Header.Content>
                </Header>
                <Grid stackable>
                    <Grid.Row columns={'equal'} stretched>
                        <Grid.Column>
                            <Statistic>
                                <Statistic.Value>{days}</Statistic.Value>
                                <Statistic.Label>Days</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                <Statistic.Value>{hours}</Statistic.Value>
                                <Statistic.Label>Hours</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                <Statistic.Value>{minutes}</Statistic.Value>
                                <Statistic.Label>Minutes</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                <Statistic.Value>{seconds}</Statistic.Value>
                                <Statistic.Label>Seconds</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </div>
    }
}

const getNumber = (num) => {
    return (num < 9) ? "0" + num : num
}

const nextWeekdayDate = (date, day_in_week) => {

    const ret = new Date(date || new Date());
    console.log('ret ', ret)
    ret.setDate(ret.getDate() + (day_in_week - 1 - ret.getDay() + 7) % 7 + 1);
    ret.setHours(17, 0, 0);
    return ret;
}

const SocietyCountdown = () => {
    return (
        <div>
            <Countdown
                date={
                    nextWeekdayDate(new Date(), 5)
                }
                renderer={renderer}
            />
        </div>
    )
}

export default SocietyCountdown