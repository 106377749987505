import React from 'react';
import {Grid, Segment} from 'semantic-ui-react'
import GreatDaneResults from "./GreatDaneResults";
import GreatDaneDrinkWeek from "./GreatDaneDrinkWeek";
import DawgsBios from "./DawgsBios";

const GreatDaneContents = () => {
    return (
        <div className='Content' >
            <Grid stackable>
                <Grid.Row columns={'equal'} stretched>
                    <Grid.Column>
                        <GreatDaneResults/>
                    </Grid.Column>
                    <Grid.Column>
                        <GreatDaneDrinkWeek/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={'equal'} stretched>
                    <Grid.Column>
                        <DawgsBios />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default GreatDaneContents